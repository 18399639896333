.entry-card {
    background: #DDF8E8;
    padding: 15px;
    width: 280px;
    margin: 10px auto;
    height: 280px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
    position: relative;
    overflow: hidden;
}

.entry-card-header {
    display: flex;
    align-items: center;
}

.entry-card-header h3 {
    font-size: 1.5em;
    font-weight: 300;
    margin: 5px;
}

.entry-card-body {
    padding: 15px;
}

.entry-card-body:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
    width: 100%;
    height: 4em;
  }

.highlight-baby {
    background: greenyellow;
    color: blueviolet;
}

.entry-bookmark {
    position: absolute;
    top: -3px;
    right: 20px;
    font-size: 30px;
}

.entry-bookmark svg {
    color: black;
    transition: .8s ease-out;
}

.entry-bookmark svg:hover {
    color: white;
}

@media only screen and (max-width: 400px) {
    .entry-card-header h3 {
        font-size: 22px;
        font-weight: 500;
    }

    .entry-date-block {
        padding-right: 10px;
    }
}


.new-user {
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url('./notepad-small.jpg') no-repeat center center ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.new-user nav {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.new-user-links {
    display: flex;
    font-size: 18px;
}

.new-user-links a {
    padding: 0 20px;
}

.new-user .entry-title {
    background: transparent;
}

.new-user .entry-date-day, .new-user .entry-date-block, .new-user .entry-date-number {
    color: rgb(97, 133, 0);
}

.calendar {
    width: 93%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* justify-content: center; */
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 79%, rgba(244,244,244,1) 100%, rgba(255,255,255,1) 100%);
    border-radius: 25px;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
    height: 93vh;
}

.calendar-weekdays {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    font-size: 22px;
    color: rgba(0,0,0,0.3);
    text-shadow: .5px 1px 1px rgba(0,0,0,0.3);
    border-top: 1px solid rgba(0,0,0,0.3);
    font-weight: 400;
}

.weekday {
    box-sizing: border-box;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    width: calc(100% / 7)
}

@media only screen and (max-width: 768px) {
    .calendar {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .calendar {
        position: relative;
        bottom: 40px;
    }
}
.block-title input {
    border: none;
    outline: none;
    font-size: 35px;
    background: inherit;
    width: 100%;
    text-align: center;
}

.block-title:focus {
    outline: none;
}

.blocks-page-header .block-title input {
    width: 700px;
}
.entry-page {
    /* display: flex; */
    width: 80%;
    text-align: left;
    height: 100%;
    margin: 0 auto;
}

.entry-page-bookmark {
    position: absolute;
    top: -5px;
    right: 50px;
    font-size: 50px;
}

.entry-page-bookmark svg {
    color: black;
    transition: .3s ease-out;
}

.entry-page-bookmark svg:hover {
    color: rgb(180, 180, 180);
}

.entry-back-arrow {
    font-size: 45px;
    margin-right: 20px;
    position: relative;
    top: 50px;
    left: -75px;
    transition: .3s;
}

.entry-back-arrow:active {
    /* box-shadow: 0 5px #666; */
    transform: translateX(-6px);
}

.entry-body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.entry-header {
    display: flex;
    justify-content: flex-start;
}

.entry-title {
    border: none;
    font-size: 32px;
    outline: none;
    padding-left: 25px;
    width: 100%;
    font-weight: 500;
}

.entry-text {
    text-align: left;
    white-space: pre-wrap;
    width: 100%;
    padding: 20px 0;
    font-size: 28px;
    font-weight: 300;
    display: block;
}


.entry-text:focus {
    outline: none
}

.entry-text-stamp {
    color: antiquewhite;
    background: black !important;
    border-radius: 8px;
    font-size: 1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

.save-entry {
    border-radius: 5px;
    outline: none;
}

.save-entry:active {
    background: black;
    color: beige;
    outline: none;
}

.stamp-hidden {
    background: lightskyblue;
    width: 220px;
    height: 280px;
    border-radius: 0 10px 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15s, opacity 0.2s linear;
    position: absolute;
}

.text-stamp-container {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .entry-page-bookmark {
        right: 20px;
    }

    .entry-page .entry-back-arrow {
        top: 0;
        left: 20px;
        font-size: 35px;
    }
    
    .entry-header {
        width: 90%;
        padding-top: 10px;
    }

    .entry-body {
        margin: 0;
    }

    .entry-bar {
        box-sizing: border-box;
        width: 100% !important;
        height: auto !important;
    }

    .entry-text {
        width: 95%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 400px) {
    .save-entry {
        position: relative;
        bottom: 45px;;
    }
}
.left-cell {
    border-left: none !important;
}

.calendar-cell {
    box-sizing: border-box;
    width: calc(100% / 7);
    /* border: .01em solid slategrey; */
    height: calc(100% / 5);
    text-decoration: none;
    color: black;
    border-bottom: none;
    border-right: none;
    transition: .5s ease-in-out;
    overflow: hidden;
}

.calendar-cell-long {
    height: calc(100% / 6);
}

.calendar-cell:hover {
    background: rgb(142, 240, 240);
}

.cell-date {
    font-size: 28px;
    text-align: left;
    border: 1px solid paleturquoise;
    /* border-top: 1px solid black;
    border-left: 1px solid black; */
    background: paleturquoise;
    width: 30px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 5px;
    border-radius: 0 0 25%;
}

.cell-text {
    font-weight: 300;
    text-align: left;
    font-size: 12px;
    padding: 10px;
    overflow: hidden;
    position: relative;
}
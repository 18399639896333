.blocks-page {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    /* might change */
    display: flex;
}

.blocks-page-header {
    display: flex;
    align-items: center;
    height: 100px;
}

.blocks-page-header input {
    width: 70%;
    box-sizing: border-box;
    padding: 5px;
    height: 60px;
    font-size: 30px;
}

.blocks-page-header input:focus {
    outline: none;
}

.toolbox-list {
    overflow: auto;
}

.block-saved-list {
    height: calc(100vh - 200px);
}

.saved-list-empty {
    font-size: 25px;
    color: red;
    margin: 25px;
}

.block-trash {
    height: 40px;
    overflow: hidden;
}

.block-trash:hover {
    background: greenyellow;
}

@media only screen and (max-width: 768px) {
    .blocks-main {
        width: 100%;
    }

    .blocks-page {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .blocks-page-header button {
        width: 110px;
    }

    .blocks-page-header button svg {
        margin: 0;
        padding: 10px;
    }

    .blocks-page-header button div {
        margin: 0;
        padding-right: 10px;
    }

    .block-saved-list {
        height: 200px;
        overflow: scroll;
    }

    .toolbox-list {
        width: 100%;
        display: block;
        position: static;
        overflow: visible;
        height: 1000px !important;
        background: ghostwhite;
    }
}
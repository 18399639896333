.entry-demo {
    height: calc(100vh - 65px);
}

.entry-demo .entry-date-number {
    color: rgb(51, 46, 46);
}

.demo-entry-body {
    width: 75%;
    margin: 0 auto;
}

.demo-entry-bar {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 18%;
    border: 1.4px solid rgb(145, 145, 145);
    background: rgb(212, 212, 212);
    border-radius: 5px;
    border-bottom: none;
    height: 70px;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    justify-self: flex-end;

}

.demo-entry-bar h4 {
    margin: 5px;
    padding: 0;
}

.demo-entry-bar-split::after {
    border: .4px solid rgb(100, 100, 100);
    content: '';
    font-size: 37px;
}

.entry-bar-section {
    width: 100%;
    padding: 0 10px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.entry-tiles {
    display: flex;
}

.entry-bar-split::after {
    border: .4px solid rgb(202, 202, 202);
    content: '';
    font-size: 37px;
}

.demo-entry-log {
    width: 50%;
    margin: 0 auto;
}

.demo-entry-log a {
    position: relative;
    right: 300px;
    top: 25px;
}

.demo-entry-log-body {
    background: whitesmoke;
    height: 60vh;
    border-radius: 25px;
    padding-top: 75px;
}
.block-weekly {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.weekly-board {
    display: flex;
}

.weekly-day {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgb(144, 144, 144);
    border-left: none;
    width: 25px;
    height: 25px;
    transition: .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.weekly-day:hover {
    background-color: rgb(193, 231, 246)
}

.weekly-day-left {
    border-left: 1px solid rgb(144, 144, 144);
    border-radius: 3px 0px 0px 3px;
}

.weekly-day-right {
    border-radius: 0px 3px 3px 0px;
}

.day-active {
    background-color: rgb(187, 197, 251);
}
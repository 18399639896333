.goal-form {
    display: flex;
    justify-content: center;
}

.goal-settings {
    display: flex;
    width: 70%;
    margin-top: 50px;
    background: seashell;
}

.goal-settings form {
    padding: 70px 10px;
    margin: 30px 10px;
    border: 1px solid seashell;
    border-radius: 25px;
    background: white;
}

.new-goal {
    width: 50%;
    box-sizing: border-box;
    border: 1px solid seashell;
}

.delete-goal {
    width: 50%;
    box-sizing: border-box;
    border: 1px solid seashell;
}
@import url(https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:300,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.missing-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.missing-page img {
    width: 40%;
    border-radius: 10px;
}


nav a {
    font-size: 20px;
    text-decoration: none;
}

.logo {
    font-size: 30px;
    margin: 10px 0;
}

.hidden {
    display: none !important;
}

.user main {
    height: 100vh;
    width: calc(100% - 200px);
    float: right;
    overflow: scroll;
    /* background: linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url('./user-background.jpg') no-repeat center center ; */
    background-size: cover;
}
.home-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto !important;
    height: 100vh;
}

.journal-feed {
    width: 100%;
}

.journal-feed .card-list {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.journal-feed .card-list .entry-card {
    width: 400px;
    height: 400px;
    -webkit-transition: .3s;
    transition: .3s;
    text-align: left;
}

.journal-feed .card-list .entry-card:hover {
    box-shadow: 0px 3px 10px rgba(0,0,0,0.5);
}

.journal-feed .card-list .entry-card:active {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
}

@media only screen and (max-width: 400px) {
    .journal-feed .card-list .entry-card {
        width: 320px;
        height: 320px;
    }
}

@media only screen and (max-width: 320px) {
    .journal-feed .card-list .entry-card {
        width: 300px;
        height: 300px;
    }
}
.sweet-loading {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.entry-card {
    background: #DDF8E8;
    padding: 15px;
    width: 280px;
    margin: 10px auto;
    height: 280px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
    position: relative;
    overflow: hidden;
}

.entry-card-header {
    display: flex;
    align-items: center;
}

.entry-card-header h3 {
    font-size: 1.5em;
    font-weight: 300;
    margin: 5px;
}

.entry-card-body {
    padding: 15px;
}

.entry-card-body:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), color-stop(90%, rgba(255,255,255, 1)));
    background-image: -webkit-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
    width: 100%;
    height: 4em;
  }

.highlight-baby {
    background: greenyellow;
    color: blueviolet;
}

.entry-bookmark {
    position: absolute;
    top: -3px;
    right: 20px;
    font-size: 30px;
}

.entry-bookmark svg {
    color: black;
    -webkit-transition: .8s ease-out;
    transition: .8s ease-out;
}

.entry-bookmark svg:hover {
    color: white;
}

@media only screen and (max-width: 400px) {
    .entry-card-header h3 {
        font-size: 22px;
        font-weight: 500;
    }

    .entry-date-block {
        padding-right: 10px;
    }
}


.entry-date {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 180px;
}

.entry-date-number {
    font-size: 45px;
    font-weight: 700;
    color: #63CCCA;
    padding-right: 5px;
}

.entry-date-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 3px;
    left: 5px;
}

.entry-date-day {
    color: darkslategrey;
    font-weight: 300;
}

.entry-date-month {
    font-weight: 600;
    text-align: left;
}
.card-list {
    display: flex;
    flex-direction: column;
}
.searchbar {
    height: 75px;
    display: flex;
    align-items: center;
    width: 400px;
    position: relative;
    right: 20px;
    padding: 30px 0 10px;
}

.searchbar form {
    width: 400px;
}


.searchbar form input {
    font-size: 25px;
    border: none;
    border-bottom: 1px solid black;
    width: 350px;
}

.searchbar form input:focus {
    outline: none;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

.searchbar form input:focus::-webkit-input-placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form input:focus::-moz-placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form input:focus:-ms-input-placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form input:focus::-ms-input-placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form input:focus::placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form button {
    font-size: 25px;
    border: none;
}

.searchbar form button:focus {
    outline: none;
}

@media only screen and (max-width: 400px) {
    .searchbar form input {
        width: 270px;
        font-size: 21px;
    }
}

@media only screen and (max-width: 320px) {

}
.entry-page {
    /* display: flex; */
    width: 80%;
    text-align: left;
    height: 100%;
    margin: 0 auto;
}

.entry-page-bookmark {
    position: absolute;
    top: -5px;
    right: 50px;
    font-size: 50px;
}

.entry-page-bookmark svg {
    color: black;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
}

.entry-page-bookmark svg:hover {
    color: rgb(180, 180, 180);
}

.entry-back-arrow {
    font-size: 45px;
    margin-right: 20px;
    position: relative;
    top: 50px;
    left: -75px;
    -webkit-transition: .3s;
    transition: .3s;
}

.entry-back-arrow:active {
    /* box-shadow: 0 5px #666; */
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
}

.entry-body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.entry-header {
    display: flex;
    justify-content: flex-start;
}

.entry-title {
    border: none;
    font-size: 32px;
    outline: none;
    padding-left: 25px;
    width: 100%;
    font-weight: 500;
}

.entry-text {
    text-align: left;
    white-space: pre-wrap;
    width: 100%;
    padding: 20px 0;
    font-size: 28px;
    font-weight: 300;
    display: block;
}


.entry-text:focus {
    outline: none
}

.entry-text-stamp {
    color: antiquewhite;
    background: black !important;
    border-radius: 8px;
    font-size: 1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

.save-entry {
    border-radius: 5px;
    outline: none;
}

.save-entry:active {
    background: black;
    color: beige;
    outline: none;
}

.stamp-hidden {
    background: lightskyblue;
    width: 220px;
    height: 280px;
    border-radius: 0 10px 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.15s, opacity 0.2s linear;
    transition: visibility 0.15s, opacity 0.2s linear;
    position: absolute;
}

.text-stamp-container {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .entry-page-bookmark {
        right: 20px;
    }

    .entry-page .entry-back-arrow {
        top: 0;
        left: 20px;
        font-size: 35px;
    }
    
    .entry-header {
        width: 90%;
        padding-top: 10px;
    }

    .entry-body {
        margin: 0;
    }

    .entry-bar {
        box-sizing: border-box;
        width: 100% !important;
        height: auto !important;
    }

    .entry-text {
        width: 95%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 400px) {
    .save-entry {
        position: relative;
        bottom: 45px;;
    }
}
.entry-bar {
    /* width: 100%; */
    display: flex;
    position: fixed;
    bottom: -8px;
    border: 1.4px solid rgb(145, 145, 145);
    background: rgb(212, 212, 212);
    border-radius: 5px;
    border-bottom: none;
    height: 70px;
    width: 70%;
    align-items: center;
    justify-content: space-between;
}

.entry-bar h4 {
    margin: 5px;
    padding: 0;
}

.entry-bar-split::after {
    border: .4px solid rgb(202, 202, 202);
    content: '';
    font-size: 37px;
}

.entry-bar-section {
    width: 100%;
    padding: 0 10px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.entry-tiles {
    display: flex;
}

@media only screen and (max-width: 400px) {
    .entry-bar {
        bottom: 0;
    }
}
.entry-stamp {
    border: 1px solid skyblue;
    padding: 5px;
    border-radius: 25px;
    background: skyblue;
    margin: 0.5px 2px;
    font-weight: 100;
    -webkit-transition: .15s;
    transition: .15s;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    color: rgb(29, 29, 29);
}

.entry-stamp-active {
    opacity: 80%;
}

.entry-stamp:hover {
    box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
}

.entry-stamp:active {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
    box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
}

.log-view {
    width: 60%;
    margin: 50px auto;
    display: flex;
}

.log-view button {
    height: 50px;
    width: 200px;
    margin: 0 10px 0;
    border-radius: 3px;
}

.logs-list {
    width: 2000px;
}

.log-block {
    padding: 5px 0;
    border-radius: 3px;
    margin: 5px 0;
    background-color: aqua;
}

.log-block .block-weekly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65%;
    background-color: aliceblue;
    margin: 0 auto;
    border-radius: 15px;
}

.log-block .block-notepad {
    margin: 0 auto;
}

.log-block .yes-no {
    max-width: 73px;
    margin: 0 auto;
}
.block-weekly {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.weekly-board {
    display: flex;
}

.weekly-day {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgb(144, 144, 144);
    border-left: none;
    width: 25px;
    height: 25px;
    -webkit-transition: .4s ease;
    transition: .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.weekly-day:hover {
    background-color: rgb(193, 231, 246)
}

.weekly-day-left {
    border-left: 1px solid rgb(144, 144, 144);
    border-radius: 3px 0px 0px 3px;
}

.weekly-day-right {
    border-radius: 0px 3px 3px 0px;
}

.day-active {
    background-color: rgb(187, 197, 251);
}
.block-checklist {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.block-checklist input {
    height: 30px;
}
.meridiem {
    -webkit-transition: 1s;
    transition: 1s;
}

.clock-block {
    display: flex;
    justify-content: center;
}

.clock-block input {
    width: 15px;
}

.block-clock {
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.clock-block input:focus {
    outline: none;
}

.block-count {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 35px;
    justify-content: center;
}

.block-count h2 {
    display: none;
}

.block-count input {
    width: 85px;
    border: none;
    background: inherit;
    outline: none;
    font-size: 35px;
}

.units {
    width: 105px !important;
    min-width: 85px;
    padding-left: 10px;
}

.number {
    max-width: 100px !important;
    width: 50px !important;
}


/* .block-count:hover h2 {
    display: block;
    font-size: 10px;
} */

.block-count:active .block-count-input {
    /* display: none; */
}

.block-date input {
    height: 30px;
}
.block-notepad {
    -webkit-transition: 2s;
    transition: 2s;
    width: 150px;
}

.block-notepad:focus {
    width: 200px;
    height: 200px;
    -webkit-transition: 1s ease-in;
    transition: 1s ease-in;
    outline: none;
    background: #ffff88;
    border-radius: 2px;
    padding: 30px;
}
.block-text {
    padding-top: 20px;
    font-size: 25px;
}

.block-title input {
    border: none;
    outline: none;
    font-size: 35px;
    background: inherit;
    width: 100%;
    text-align: center;
}

.block-title:focus {
    outline: none;
}

.blocks-page-header .block-title input {
    width: 700px;
}
.yes-no {
    display: flex;
    outline: 1px solid black;
    margin: 0;
    border-radius: 25px;
}

.block-bool {
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    padding: 5px 7px;
    background: white;
}

.bool-active {
    background: black;
    color: white;
}
/* Import fonts */

.sidebar {
    display: flex;
    flex-direction: column;
    float: left;
    width: 190px;
    text-align: left;
    background: #FFF9F9;
    height: 100vh;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    position: absolute;
}

.sidebar-icon {
    background: black;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 15px;
    font-size: 18px;
}

nav a {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #5A5353;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    /* border-left: 5px solid transparent; */
}

nav a:hover {
    background: rgb(134, 184, 231);
}

.nav-active {
    background: lightblue;
    border-left: 10px solid #5A5353;
    
}

.nav-logo {
    font-family: 'Crimson Text', serif;
    letter-spacing: 0.3ch;
    font-size: 50px;
}

.nav-logo:hover {
    background: #FFF9F9;
}

.nav-logo img {
    width: 45px;
    padding-right: 10px;
}

.nav-end {
    margin-top: auto;
}

.logo {
    font-size: 30px;
    text-align: center;
    margin: 15px 15px;
    display: flex;
    align-items: center;
}
.calendar-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.calendar {
    width: 93%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* justify-content: center; */
    background: rgb(255,255,255);
    background: -webkit-gradient(linear, left top, right top, color-stop(79%, rgba(255,255,255,1)), color-stop(100%, rgba(244,244,244,1)), to(rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 79%, rgba(244,244,244,1) 100%, rgba(255,255,255,1) 100%);
    background: linear-gradient(90deg, rgba(255,255,255,1) 79%, rgba(244,244,244,1) 100%, rgba(255,255,255,1) 100%);
    border-radius: 25px;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.1);
    height: 93vh;
}

.calendar-weekdays {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    font-size: 22px;
    color: rgba(0,0,0,0.3);
    text-shadow: .5px 1px 1px rgba(0,0,0,0.3);
    border-top: 1px solid rgba(0,0,0,0.3);
    font-weight: 400;
}

.weekday {
    box-sizing: border-box;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    width: calc(100% / 7)
}

@media only screen and (max-width: 768px) {
    .calendar {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .calendar {
        position: relative;
        bottom: 40px;
    }
}
.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    width: 100%;
    height: 80px;
}

.current-month {
    font-size: 40px;
    height: 80px;
    display: flex;
    align-items: center;
}

.calendar-icon {
    font-size: 30px;
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
}

.icon-left {
    position: relative;
    right: -60px;
    -webkit-transition: .8s ease-in-out .3;
    transition: .8s ease-in-out .3;
}

.icon-left:active {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
}

.icon-right {
    position: relative;
    left: -60px;
    -webkit-transition: .8s ease-in-out;
    transition: .8s ease-in-out;
}

.icon-right:active {
    -webkit-transform: translateX(3px);
            transform: translateX(3px);
}

@media only screen and (max-width: 400px) {
    .icon-left {
        right: -20px;
    }
    .icon-right {
        left: -20px;
    }
}
.calendar-days {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 80px);
}

.left-cell {
    border-left: none !important;
}

.calendar-cell {
    box-sizing: border-box;
    width: calc(100% / 7);
    /* border: .01em solid slategrey; */
    height: calc(100% / 5);
    text-decoration: none;
    color: black;
    border-bottom: none;
    border-right: none;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    overflow: hidden;
}

.calendar-cell-long {
    height: calc(100% / 6);
}

.calendar-cell:hover {
    background: rgb(142, 240, 240);
}

.cell-date {
    font-size: 28px;
    text-align: left;
    border: 1px solid paleturquoise;
    /* border-top: 1px solid black;
    border-left: 1px solid black; */
    background: paleturquoise;
    width: 30px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 5px;
    border-radius: 0 0 25%;
}

.cell-text {
    font-weight: 300;
    text-align: left;
    font-size: 12px;
    padding: 10px;
    overflow: hidden;
    position: relative;
}
.calendar-view {
    height: 100vh;
    width: 100%;
    margin: 0 auto;

}
.reminder {
    border: 1px solid rgb(212, 255, 143);
    border-radius: 20px;
    width: 40%;
    margin: 5px auto;
    padding: 10px;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    -webkit-transition-delay: .8;
            transition-delay: .8;
}

.reminder:hover {
    background: rgb(212, 255, 143);
}
.reminder-title {
    font-weight: 700;
    text-align: left;
    font-size: 25px;
    text-transform: capitalize;
}

.reminder-details {
    text-align: right;
}
.reminder-form {
    /* display: block; Hidden by default */
    position: absolute;
    top: 0;
    z-index: 1; /* Sit on top */
    /* left: calc(200px + 24%); */
    width: calc(100% - 200px); /* Full width */
    height: auto; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* border-radius: 70px; */
    color: black;
    display: flex;
    padding: 30px;
    box-sizing: border-box;
}

.reminder-form .entry-back-arrow {
    left: 100px;
}

.reminder-settings {
    width: 60%;
    background: lightblue;
    border-radius: 3px;
    padding: 20px;
    margin: 30px auto 0;
    box-sizing: border-box;
}

.reminder-toggle {
    /* height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 52px;
    display: flex;
    justify-content: center;
    transition: .5s ease-out; */
    padding: 15px 15px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: black;
    background-color: inherit;
    border: none;
    border-radius: 15px;
}

.reminder-settings button, .reminder-settings select {
    background: whitesmoke;
}

.new-reminder, .delete-reminder {
    background: white;
    width: 65%;
    margin: 20px auto;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.new-reminder form, .delete-reminder form {
    width: 100%;
}


.reminder-toggle:active {
    /* box-shadow: 0 5px #666; */
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

@media only screen and (max-width: 768px) {
    .reminder-settings {
        width: 100%;
    }
}
.add-reminder {
    font-size: 30px;
    border-radius: 50%;
    margin-top: 25px;
    width: 45px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.reminders-page h2 {
    text-align: left;
    width: 40%;
    margin: 25px auto;
}

.reminder-header {
    width: 40%;
    margin: 0 auto;
}

.reminder-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(23, 255, 224);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.reminder-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;
}



/* adsjknknja */

.add-reminder {
    font-size: 30px;
    border-radius: 5px;
    margin: 25px;
    padding-bottom: 25px;
    width: 120px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.actions {
    width: 40%;
    margin: 0 auto;
}

.action-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    letter-spacing: 3px;
}

.reminder-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(172, 202, 88);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.action-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;


}

.goal-form .entry-back-arrow {
    left: -80px;
}

.reminders-newbie {
    padding: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgb(14, 84, 128);
    padding-top: 10%;
}

@media only screen and (max-width: 768px) {
    .reminders-page {
        height: 100vh;
    }

    .reminder {
        width: 80%;
    }

    .reminder-form {
        width: 100%;
        position: static;
        display: block;
    }

    .reminders-page .entry-back-arrow {
        left: 20px !important;
        position: absolute;
        top: 100px;
    }
}
.blocks-page {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    /* might change */
    display: flex;
}

.blocks-page-header {
    display: flex;
    align-items: center;
    height: 100px;
}

.blocks-page-header input {
    width: 70%;
    box-sizing: border-box;
    padding: 5px;
    height: 60px;
    font-size: 30px;
}

.blocks-page-header input:focus {
    outline: none;
}

.toolbox-list {
    overflow: auto;
}

.block-saved-list {
    height: calc(100vh - 200px);
}

.saved-list-empty {
    font-size: 25px;
    color: red;
    margin: 25px;
}

.block-trash {
    height: 40px;
    overflow: hidden;
}

.block-trash:hover {
    background: greenyellow;
}

@media only screen and (max-width: 768px) {
    .blocks-main {
        width: 100%;
    }

    .blocks-page {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .blocks-page-header button {
        width: 110px;
    }

    .blocks-page-header button svg {
        margin: 0;
        padding: 10px;
    }

    .blocks-page-header button div {
        margin: 0;
        padding-right: 10px;
    }

    .block-saved-list {
        height: 200px;
        overflow: scroll;
    }

    .toolbox-list {
        width: 100%;
        display: block;
        position: static;
        overflow: visible;
        height: 1000px !important;
        background: ghostwhite;
    }
}

.add-reminder {
    font-size: 30px;
    border-radius: 5px;
    margin-top: 25px;
    padding-bottom: 25px;
    width: 120px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.actions {
    width: 40%;
    margin: 0 auto;
}

.action-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    letter-spacing: 3px;
}

.action-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(172, 202, 88);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.action-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;


}

.goal-form .entry-back-arrow {
    left: -80px;
}

.actions-newbie {
    padding: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgb(14, 84, 128);
    padding-top: 10%;
}

.settings-arrow {
    font-size: 40px;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .actions {
        width: 80%;
    }

    .goal-form {
        display: block !important;
    }

    .goal-settings {
        width: 90% !important;
        margin: 0 auto !important;
        flex-direction: column;
    }

    .new-goal {
        width: 100% !important;
    }

    .delete-goal {
        width: 100% !important;
    }

    .goal-form .entry-back-arrow {
        left: 20px !important;
        position: absolute;
        top: 70px;
    }
}

@media only screen and (max-width: 400px) {
    .action-header h2 {
        right: 90px;
    }
}
.goal {
    border: 1px solid powderblue;
    border-radius: 20px;
    width: 100%;
    margin: 5px auto;
    padding: 10px;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

.goal:hover {
    background: powderblue;
}

.goal-title {
    font-weight: 700;
    text-align: left;
    font-size: 25px;
    text-transform: capitalize;
}

.goal-details {
    text-align: right;
}
.goal-form {
    display: flex;
    justify-content: center;
}

.goal-settings {
    display: flex;
    width: 70%;
    margin-top: 50px;
    background: seashell;
}

.goal-settings form {
    padding: 70px 10px;
    margin: 30px 10px;
    border: 1px solid seashell;
    border-radius: 25px;
    background: white;
}

.new-goal {
    width: 50%;
    box-sizing: border-box;
    border: 1px solid seashell;
}

.delete-goal {
    width: 50%;
    box-sizing: border-box;
    border: 1px solid seashell;
}
.landing-page p {
    font-size: 25px;
    padding: 5px;

}

.landing-desc {
    margin-top: 18%;
    padding-bottom: 40px;
}

.demo-button {
    text-decoration: none;
    background: cornflowerblue;
    padding: 15px;
    border-radius: 25px;
}
.LoginForm {
    background: rgb(82, 82, 82);
    width: 40%;
    margin: 0 auto;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.LoginForm input, .LoginForm button {
    box-sizing: border-box;
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    border: 1px solid steelblue;
    background-color: fade(white, 20%);
    width: 250px;
    
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    
    color: green;
    
    -webkit-transition-duration: 0.25s;
    
            transition-duration: 0.25s;
    font-weight: 300;
}

.loading-msg {
    font-size: 18px;
    padding: 10px;
    color: ivory;
}

.LoginForm input:hover{
    background-color: fade(white, 40%);
}

.LoginForm input:focus{
    background-color: white;
    width: 300px;
    
    color: green;
}

.LoginForm input label {
    display: none;
}

.user_name label, .password label {
    display: none;
}

.LoginForm button {
    color: black;
    
}
.RegistrationForm {
    background: rgb(82, 82, 82);
    width: 40%;
    margin: 0 auto;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.RegistrationForm input, .RegistrationForm button {
    box-sizing: border-box;
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    border: 1px solid steelblue;
    background-color: fade(white, 20%);
    width: 250px;
    
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    
    color: black;
    
    -webkit-transition-duration: 0.25s;
    
            transition-duration: 0.25s;
    font-weight: 300;

}

.RegistrationForm input:hover{
    background-color: fade(white, 40%);
}

.RegistrationForm input:focus{
    background-color: white;
    width: 300px;
    
    color: green;
}

.RegistrationForm label {
    display: none;
}

.user_name label, .password label {
    display: none;
}

.registrationForm button {
    color: black;
    
}
.new-user {
    height: 100vh;
    overflow: hidden;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.4)), to(rgba(255,255,255,0.4))), url(../../static/media/notepad-small.7ec99ad6.jpg) no-repeat center center ;
    background: -webkit-linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url(../../static/media/notepad-small.7ec99ad6.jpg) no-repeat center center ;
    background: linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url(../../static/media/notepad-small.7ec99ad6.jpg) no-repeat center center;
    background-size: cover;
}

.new-user nav {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.new-user-links {
    display: flex;
    font-size: 18px;
}

.new-user-links a {
    padding: 0 20px;
}

.new-user .entry-title {
    background: transparent;
}

.new-user .entry-date-day, .new-user .entry-date-block, .new-user .entry-date-number {
    color: rgb(97, 133, 0);
}

.entry-demo {
    height: calc(100vh - 65px);
}

.entry-demo .entry-date-number {
    color: rgb(51, 46, 46);
}

.demo-entry-body {
    width: 75%;
    margin: 0 auto;
}

.demo-entry-bar {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 18%;
    border: 1.4px solid rgb(145, 145, 145);
    background: rgb(212, 212, 212);
    border-radius: 5px;
    border-bottom: none;
    height: 70px;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    justify-self: flex-end;

}

.demo-entry-bar h4 {
    margin: 5px;
    padding: 0;
}

.demo-entry-bar-split::after {
    border: .4px solid rgb(100, 100, 100);
    content: '';
    font-size: 37px;
}

.entry-bar-section {
    width: 100%;
    padding: 0 10px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.entry-tiles {
    display: flex;
}

.entry-bar-split::after {
    border: .4px solid rgb(202, 202, 202);
    content: '';
    font-size: 37px;
}

.demo-entry-log {
    width: 50%;
    margin: 0 auto;
}

.demo-entry-log a {
    position: relative;
    right: 300px;
    top: 25px;
}

.demo-entry-log-body {
    background: whitesmoke;
    height: 60vh;
    border-radius: 25px;
    padding-top: 75px;
}
::-webkit-scrollbar { 
  display: none; 
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html, body {
  margin: 0; 
  height: 100%; 
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mobile-header {
  display: none;
}

@media only screen and (max-width: 768px) {
  html, body {
    overflow: hidden;
  }

  .App {
    width: 100vw;
  }

  main {
    width: 100% !important;
  }

  .nav-hidden {
    /* display: none; */
    opacity: 0;
    visibility: hidden;
  }

  .sidebar-max {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 30px;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }

  .sidebar-min {
    z-index: 1000;
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 50%;
  }

  .sidebar {
    width: 100% !important;
    z-index: 100;
    -webkit-transition: 1s;
    transition: 1s;
  }

  .mobile-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 70px;
    font-family: 'Crimson Text', serif;
  }

  .mobile-header .logo {
    margin: 0px;
    font-weight: 600;
  }

  .mobile-header img {
    width: 45px;
  }

  .landing-desc {
      width: 88%;
      margin: 0 auto;
      padding-top: 30px;
  }

  .LoginForm, .RegistrationForm {
      width: 90%;
  }

  .demo-entry .entry-back-arrow {
      top: 0;
      left: 20px;
      font-size: 35px;
  }
  
  .demo-entry {
      width: 100%;
  }

  .entry-page {
      width: 100%;
  }

  .entry-text {
      font-size: 18px !important;
  }

  .demo-entry-body {
      width: 85% !important;
      padding-top: 20px;
  }

  .demo-entry-bar {
      width: 95% !important;
      left: 2.5% !important;
      height: auto !important;
  }

  .entry-title {
      font-size: 25px !important;
  }

  .entry-stamp {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
  }

  .searchbar {
    right: 0;
    padding: 5px;
  }

  .searchbar button {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .entry-title {
      font-size: 22px !important;
  }
}

.reminder-form {
    /* display: block; Hidden by default */
    position: absolute;
    top: 0;
    z-index: 1; /* Sit on top */
    /* left: calc(200px + 24%); */
    width: calc(100% - 200px); /* Full width */
    height: auto; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* border-radius: 70px; */
    color: black;
    display: flex;
    padding: 30px;
    box-sizing: border-box;
}

.reminder-form .entry-back-arrow {
    left: 100px;
}

.reminder-settings {
    width: 60%;
    background: lightblue;
    border-radius: 3px;
    padding: 20px;
    margin: 30px auto 0;
    box-sizing: border-box;
}

.reminder-toggle {
    /* height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 52px;
    display: flex;
    justify-content: center;
    transition: .5s ease-out; */
    padding: 15px 15px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: black;
    background-color: inherit;
    border: none;
    border-radius: 15px;
}

.reminder-settings button, .reminder-settings select {
    background: whitesmoke;
}

.new-reminder, .delete-reminder {
    background: white;
    width: 65%;
    margin: 20px auto;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.new-reminder form, .delete-reminder form {
    width: 100%;
}


.reminder-toggle:active {
    /* box-shadow: 0 5px #666; */
    transform: translateY(4px);
}

@media only screen and (max-width: 768px) {
    .reminder-settings {
        width: 100%;
    }
}
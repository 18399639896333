.block-notepad {
    transition: 2s;
    width: 150px;
}

.block-notepad:focus {
    width: 200px;
    height: 200px;
    transition: 1s ease-in;
    outline: none;
    background: #ffff88;
    border-radius: 2px;
    padding: 30px;
}
.missing-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.missing-page img {
    width: 40%;
    border-radius: 10px;
}
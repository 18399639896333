.RegistrationForm {
    background: rgb(82, 82, 82);
    width: 40%;
    margin: 0 auto;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.RegistrationForm input, .RegistrationForm button {
    box-sizing: border-box;
    display: block;
    appearance: none;
    outline: 0;
    border: 1px solid steelblue;
    background-color: fade(white, 20%);
    width: 250px;
    
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    
    color: black;
    
    transition-duration: 0.25s;
    font-weight: 300;

}

.RegistrationForm input:hover{
    background-color: fade(white, 40%);
}

.RegistrationForm input:focus{
    background-color: white;
    width: 300px;
    
    color: green;
}

.RegistrationForm label {
    display: none;
}

.user_name label, .password label {
    display: none;
}

.registrationForm button {
    color: black;
    
}
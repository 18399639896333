.searchbar {
    height: 75px;
    display: flex;
    align-items: center;
    width: 400px;
    position: relative;
    right: 20px;
    padding: 30px 0 10px;
}

.searchbar form {
    width: 400px;
}


.searchbar form input {
    font-size: 25px;
    border: none;
    border-bottom: 1px solid black;
    width: 350px;
}

.searchbar form input:focus {
    outline: none;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}

.searchbar form input:focus::placeholder{
    color:transparent;
    text-shadow: none;
}

.searchbar form button {
    font-size: 25px;
    border: none;
}

.searchbar form button:focus {
    outline: none;
}

@media only screen and (max-width: 400px) {
    .searchbar form input {
        width: 270px;
        font-size: 21px;
    }
}

@media only screen and (max-width: 320px) {

}
.meridiem {
    transition: 1s;
}

.clock-block {
    display: flex;
    justify-content: center;
}

.clock-block input {
    width: 15px;
}

.block-clock {
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.clock-block input:focus {
    outline: none;
}

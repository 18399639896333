/* Import fonts */

@import url('https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans:300,400&display=swap');

.sidebar {
    display: flex;
    flex-direction: column;
    float: left;
    width: 190px;
    text-align: left;
    background: #FFF9F9;
    height: 100vh;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    position: absolute;
}

.sidebar-icon {
    background: black;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 15px;
    font-size: 18px;
}

nav a {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #5A5353;
    transition: 0.2s;
    /* border-left: 5px solid transparent; */
}

nav a:hover {
    background: rgb(134, 184, 231);
}

.nav-active {
    background: lightblue;
    border-left: 10px solid #5A5353;
    
}

.nav-logo {
    font-family: 'Crimson Text', serif;
    letter-spacing: 0.3ch;
    font-size: 50px;
}

.nav-logo:hover {
    background: #FFF9F9;
}

.nav-logo img {
    width: 45px;
    padding-right: 10px;
}

.nav-end {
    margin-top: auto;
}

.logo {
    font-size: 30px;
    text-align: center;
    margin: 15px 15px;
    display: flex;
    align-items: center;
}
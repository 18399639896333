.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    width: 100%;
    height: 80px;
}

.current-month {
    font-size: 40px;
    height: 80px;
    display: flex;
    align-items: center;
}

.calendar-icon {
    font-size: 30px;
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
}

.icon-left {
    position: relative;
    right: -60px;
    transition: .8s ease-in-out .3;
}

.icon-left:active {
    transform: translateX(-3px);
}

.icon-right {
    position: relative;
    left: -60px;
    transition: .8s ease-in-out;
}

.icon-right:active {
    transform: translateX(3px);
}

@media only screen and (max-width: 400px) {
    .icon-left {
        right: -20px;
    }
    .icon-right {
        left: -20px;
    }
}
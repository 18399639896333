::-webkit-scrollbar { 
  display: none; 
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html, body {
  margin: 0; 
  height: 100%; 
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-header {
  display: none;
}

@media only screen and (max-width: 768px) {
  html, body {
    overflow: hidden;
  }

  .App {
    width: 100vw;
  }

  main {
    width: 100% !important;
  }

  .nav-hidden {
    /* display: none; */
    opacity: 0;
    visibility: hidden;
  }

  .sidebar-max {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 30px;
    transition: .5s ease-in-out;
  }

  .sidebar-min {
    z-index: 1000;
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 50%;
  }

  .sidebar {
    width: 100% !important;
    z-index: 100;
    transition: 1s;
  }

  .mobile-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 70px;
    font-family: 'Crimson Text', serif;
  }

  .mobile-header .logo {
    margin: 0px;
    font-weight: 600;
  }

  .mobile-header img {
    width: 45px;
  }

  .landing-desc {
      width: 88%;
      margin: 0 auto;
      padding-top: 30px;
  }

  .LoginForm, .RegistrationForm {
      width: 90%;
  }

  .demo-entry .entry-back-arrow {
      top: 0;
      left: 20px;
      font-size: 35px;
  }
  
  .demo-entry {
      width: 100%;
  }

  .entry-page {
      width: 100%;
  }

  .entry-text {
      font-size: 18px !important;
  }

  .demo-entry-body {
      width: 85% !important;
      padding-top: 20px;
  }

  .demo-entry-bar {
      width: 95% !important;
      left: 2.5% !important;
      height: auto !important;
  }

  .entry-title {
      font-size: 25px !important;
  }

  .entry-stamp {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
  }

  .searchbar {
    right: 0;
    padding: 5px;
  }

  .searchbar button {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 400px) {
  .entry-title {
      font-size: 22px !important;
  }
}
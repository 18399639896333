.home-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto !important;
    height: 100vh;
}

.journal-feed {
    width: 100%;
}

.journal-feed .card-list {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.journal-feed .card-list .entry-card {
    width: 400px;
    height: 400px;
    transition: .3s;
    text-align: left;
}

.journal-feed .card-list .entry-card:hover {
    box-shadow: 0px 3px 10px rgba(0,0,0,0.5);
}

.journal-feed .card-list .entry-card:active {
    transform: translateY(3px);
}

@media only screen and (max-width: 400px) {
    .journal-feed .card-list .entry-card {
        width: 320px;
        height: 320px;
    }
}

@media only screen and (max-width: 320px) {
    .journal-feed .card-list .entry-card {
        width: 300px;
        height: 300px;
    }
}
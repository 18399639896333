.add-reminder {
    font-size: 30px;
    border-radius: 50%;
    margin-top: 25px;
    width: 45px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.reminders-page h2 {
    text-align: left;
    width: 40%;
    margin: 25px auto;
}

.reminder-header {
    width: 40%;
    margin: 0 auto;
}

.reminder-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(23, 255, 224);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.reminder-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;
}



/* adsjknknja */

.add-reminder {
    font-size: 30px;
    border-radius: 5px;
    margin: 25px;
    padding-bottom: 25px;
    width: 120px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.actions {
    width: 40%;
    margin: 0 auto;
}

.action-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    letter-spacing: 3px;
}

.reminder-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(172, 202, 88);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.action-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;


}

.goal-form .entry-back-arrow {
    left: -80px;
}

.reminders-newbie {
    padding: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgb(14, 84, 128);
    padding-top: 10%;
}

@media only screen and (max-width: 768px) {
    .reminders-page {
        height: 100vh;
    }

    .reminder {
        width: 80%;
    }

    .reminder-form {
        width: 100%;
        position: static;
        display: block;
    }

    .reminders-page .entry-back-arrow {
        left: 20px !important;
        position: absolute;
        top: 100px;
    }
}
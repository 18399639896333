.block-count {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 35px;
    justify-content: center;
}

.block-count h2 {
    display: none;
}

.block-count input {
    width: 85px;
    border: none;
    background: inherit;
    outline: none;
    font-size: 35px;
}

.units {
    width: 105px !important;
    min-width: 85px;
    padding-left: 10px;
}

.number {
    max-width: 100px !important;
    width: 50px !important;
}


/* .block-count:hover h2 {
    display: block;
    font-size: 10px;
} */

.block-count:active .block-count-input {
    /* display: none; */
}
.add-reminder {
    font-size: 30px;
    border-radius: 5px;
    margin-top: 25px;
    padding-bottom: 25px;
    width: 120px;
    height: 45px;
}

.add-reminder:focus {
    outline: none;
}

.actions {
    width: 40%;
    margin: 0 auto;
}

.action-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    letter-spacing: 3px;
}

.action-header h2 {
    text-transform: uppercase;
    font-weight: 200;
    border-bottom: 1px solid rgb(172, 202, 88);
    min-width: 60px;
    text-align: left;
    position: relative;
    right: 120px;
}

.action-header h2::first-letter {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 70px;
    border-radius: 3px;
    border-bottom: none;


}

.goal-form .entry-back-arrow {
    left: -80px;
}

.actions-newbie {
    padding: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgb(14, 84, 128);
    padding-top: 10%;
}

.settings-arrow {
    font-size: 40px;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .actions {
        width: 80%;
    }

    .goal-form {
        display: block !important;
    }

    .goal-settings {
        width: 90% !important;
        margin: 0 auto !important;
        flex-direction: column;
    }

    .new-goal {
        width: 100% !important;
    }

    .delete-goal {
        width: 100% !important;
    }

    .goal-form .entry-back-arrow {
        left: 20px !important;
        position: absolute;
        top: 70px;
    }
}

@media only screen and (max-width: 400px) {
    .action-header h2 {
        right: 90px;
    }
}
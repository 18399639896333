.entry-bar {
    /* width: 100%; */
    display: flex;
    position: fixed;
    bottom: -8px;
    border: 1.4px solid rgb(145, 145, 145);
    background: rgb(212, 212, 212);
    border-radius: 5px;
    border-bottom: none;
    height: 70px;
    width: 70%;
    align-items: center;
    justify-content: space-between;
}

.entry-bar h4 {
    margin: 5px;
    padding: 0;
}

.entry-bar-split::after {
    border: .4px solid rgb(202, 202, 202);
    content: '';
    font-size: 37px;
}

.entry-bar-section {
    width: 100%;
    padding: 0 10px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.entry-tiles {
    display: flex;
}

@media only screen and (max-width: 400px) {
    .entry-bar {
        bottom: 0;
    }
}
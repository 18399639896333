.yes-no {
    display: flex;
    outline: 1px solid black;
    margin: 0;
    border-radius: 25px;
}

.block-bool {
    transition: .4s ease-in-out;
    padding: 5px 7px;
    background: white;
}

.bool-active {
    background: black;
    color: white;
}


nav a {
    font-size: 20px;
    text-decoration: none;
}

.logo {
    font-size: 30px;
    margin: 10px 0;
}

.hidden {
    display: none !important;
}

.user main {
    height: 100vh;
    width: calc(100% - 200px);
    float: right;
    overflow: scroll;
    /* background: linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url('./user-background.jpg') no-repeat center center ; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}